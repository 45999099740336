export default {
  navLogoAndText: '#e6f1ff',
  navBackground: '#141416',
  navBackgroundAlt: '#23262F',
  navText: '#e6f1ff',
  navTitle: 'rgb(70, 81, 200)',

  loaderText: '#e6f1ff',
  loaderBackground: '#141416',

  textColor: '#e6f1ff',
  textColorReverse: '#141416',
  textWhite: '#e6f1ff',
  titleColor: 'rgb(63, 81, 181)',
  titleColorAlt: '#e6f1ff',

  featuredLabel: '#e6f1ff',
  featuredName: '#e6f1ff',

  white: '#e6f1ff',
  lightGrey: '#606a86',
  blue: 'rgb(63, 81, 181)',
  navy: '#141416',
  transBlue: 'rgba(63, 81, 181, 0.2)',
};
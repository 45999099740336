import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import sr from "@utils/sr";
import { srConfig, email } from "@config";
import styled from "styled-components";
import { theme, mixins, media, Section, Heading } from "@styles";
const { fontSizes, fonts } = theme;

const Contact = ({ data }) => {
  const { frontmatter, html } = data[0].node;
  const { title, buttonText } = frontmatter;
  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  return (
    <StyledContainer id="contact" ref={revealContainer}>
      <Heading>What&apos;s Next?</Heading>
      <StyledTitle>{title}</StyledTitle>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <StyledEmailLink
        href={`mailto:${email}`}
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        {buttonText}
      </StyledEmailLink>
    </StyledContainer>
  );
};

Contact.propTypes = {
  data: PropTypes.array.isRequired
};

export default Contact;

const StyledContainer = styled(Section)`
  color: ${(props) => props.theme.textColor};
  text-align: center;
  max-width: 600px;
  margin: 0 auto 100px;
  a {
    ${mixins.inlineLink};
  }
`;

const StyledTitle = styled.h4`
  margin: 0 0 20px;
  font-size: 60px;
  color: ${(props) => props.theme.blue};
  ${media.desktop`font-size: 50px;`};
  ${media.tablet`font-size: 40px;`};
`;

const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;

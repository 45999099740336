import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import sr from "@utils/sr";
import { srConfig } from "@config";
import styled from "styled-components";
import { theme, mixins, media, Section, Heading } from "@styles";
const { fontSizes, fonts } = theme;

const Jobs = ({ data }) => {
  const [activeTabId, setActiveTabId] = useState(0);
  const [tabFocus, setTabFocus] = useState(null);
  const tabs = useRef([]);

  const revealContainer = useRef(null);
  useEffect(() => sr.reveal(revealContainer.current, srConfig()), []);

  const focusTab = () => {
    if (tabs.current[tabFocus]) {
      tabs.current[tabFocus].focus();
    } else {
      // If we're at the end, go to the start
      if (tabFocus >= tabs.current.length) {
        setTabFocus(0);
      }
      // If we're at the start, move to the end
      if (tabFocus < 0) {
        setTabFocus(tabs.current.length - 1);
      }
    }
  };

  // Only re-run the effect if tabFocus changes
  useEffect(() => focusTab(), [tabFocus]);

  const onKeyPressed = (e) => {
    if (e.keyCode === 38 || e.keyCode === 40) {
      e.preventDefault();
      if (e.keyCode === 40) {
        // Move down
        setTabFocus(tabFocus + 1);
      } else if (e.keyCode === 38) {
        // Move up
        setTabFocus(tabFocus - 1);
      }
    }
  };

  return (
    <StyledContainer id="jobs" ref={revealContainer}>
      <Heading>Where I&apos;ve Worked</Heading>
      <StyledTabs>
        <StyledTabList
          role="tablist"
          aria-label="Job tabs"
          onKeyDown={(e) => onKeyPressed(e)}
        >
          {data &&
            data.map(({ node }, i) => {
              const { company } = node.frontmatter;
              return (
                <li key={i}>
                  <StyledTabButton
                    isActive={activeTabId === i}
                    onClick={() => setActiveTabId(i)}
                    ref={(el) => (tabs.current[i] = el)}
                    id={`tab-${i}`}
                    role="tab"
                    aria-selected={activeTabId === i ? true : false}
                    aria-controls={`panel-${i}`}
                    tabIndex={activeTabId === i ? "0" : "-1"}
                  >
                    <span>{company}</span>
                  </StyledTabButton>
                </li>
              );
            })}
        </StyledTabList>
        {data &&
          data.map(({ node }, i) => {
            const { frontmatter, html } = node;
            const { title, url, company, range } = frontmatter;
            return (
              <StyledTabContent
                key={i}
                isActive={activeTabId === i}
                id={`panel-${i}`}
                role="tabpanel"
                aria-labelledby={`tab-${i}`}
                tabIndex={activeTabId === i ? "0" : "-1"}
                hidden={activeTabId !== i}
              >
                <StyledJobTitle>
                  <span>{title}</span>
                  <StyledCompany>
                    <span>&nbsp;@&nbsp;</span>
                    <a
                      href={url}
                      target="_blank"
                      rel="nofollow noopener noreferrer"
                    >
                      {company}
                    </a>
                  </StyledCompany>
                </StyledJobTitle>
                <StyledJobDetails>
                  <span>{range}</span>
                </StyledJobDetails>
                <StyledDivdetals dangerouslySetInnerHTML={{ __html: html }} />
              </StyledTabContent>
            );
          })}
      </StyledTabs>
    </StyledContainer>
  );
};

Jobs.propTypes = {
  data: PropTypes.array.isRequired
};

export default Jobs;

const StyledContainer = styled(Section)`
  position: relative;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledTabs = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  ${media.thone`
    display: block;
  `};
`;

const StyledTabList = styled.ul`
  display: block;
  list-style: none;

  ${media.thone`
    display: flex;
    overflow-x: scroll;
    margin-bottom: 25px;
    width: calc(100% + 100px);
    margin-left: -50px;
    padding-right: 50px;
  `};

  ${media.phablet`
    width: calc(100% + 50px);
    margin-left: -25px;
  `};

  li {
    &:first-of-type {
      ${media.thone`
        margin-left: 50px;
      `};
      ${media.phablet`
        margin-left: 25px;
      `};
    }
    &:last-of-type {
      ${media.thone`
        padding-right: 50px;
      `};
      ${media.phablet`
        padding-right: 25px;
      `};
    }
  }
`;

const StyledTabButton = styled.button`
  ${mixins.link};
  display: flex;
  align-items: center;
  width: 100%;
  height: ${theme.tabHeight}px;
  padding: 0 20px 2px;
  transition: ${theme.transition};
  text-align: left;
  white-space: nowrap;
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.smish};
  &:hover,
  &:focus {
    background-color: ${(props) => props.theme.blue} !important;
    color: ${(props) => props.theme.white} !important;
  }
  color: ${(props) =>
    props.isActive ? props.theme.white : props.theme.lightGrey};
  border-left: 2px solid
    ${(props) => (props.isActive ? props.theme.blue : props.theme.textColor)};
  background-color: ${(props) =>
    props.isActive ? props.theme.blue : "transparent"};
  ${media.tablet`padding: 0 15px 2px;`};
  ${media.thone`
    ${mixins.flexCenter};
    padding: 0 20px;
    text-align: center;
    border-bottom: 2px solid ${(props) =>
      props.isActive ? props.theme.blue : props.theme.textColor};
    position: relative;
    z-index: 1;
    border-left: none;
    width: auto;
  `};
`;

const StyledTabContent = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 12px;
  padding-left: 30px;
  ${media.tablet`padding-left: 20px;`};
  ${media.thone`padding-left: 0;`};

  ul {
    ${mixins.fancyList};
  }
  a {
    ${mixins.inlineLink};
  }
`;

const StyledDivdetals = styled.div`
  color: ${(props) => props.theme.textColor};
`;

const StyledJobTitle = styled.h4`
  color: ${(props) => props.theme.blue};
  font-size: ${fontSizes.xxl};
  font-weight: 500;
  margin-bottom: 5px;
`;

const StyledCompany = styled.span`
  color: ${(props) => props.theme.blue};
`;

const StyledJobDetails = styled.h5`
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.smish};
  font-weight: normal;
  letter-spacing: 0.05em;
  color: ${(props) => props.theme.blue};
  margin-bottom: 30px;
  svg {
    width: 15px;
  }
`;

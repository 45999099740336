module.exports = {
  siteTitle: 'Juan Luis Flores | Software Engineer',
  siteDescription:
    'Juan Luis Flores is a software engineer based in Mexico City, who specializes in building (and occasionally designing) exceptional websites, applications, and everything in between.',
  siteKeywords:
    'Juan Luis Flores, Juan Luis, Flores, jluisfgarza, software engineer, front-end engineer, web developer, javascript, cpp, c++',
  siteUrl: 'https://juanluisfg.com',
  siteLanguage: 'en_US',
  googleAnalyticsID: 'UA-86305308-1',
  googleVerification: 'e0zZPVJyCxyyUneex1A-xVtWc8usSiSCWKogZ3g_oG8',
  name: 'Juan Luis Flores',
  location: 'Mexico City, MX',
  email: 'jluisfgarza@gmail.com',
  github: 'https://github.com/jluisfgarza',
  twitterHandle: '@jluisfgarza',
  socialMedia: [
    {
      name: 'GitHub',
      url: 'https://github.com/jluisfgarza',
    },
    {
      name: 'Linkedin',
      url: 'https://www.linkedin.com/in/jluisfgarza',
    },
    // {
    //   name: 'Instagram',
    //   url: 'https://www.instagram.com/jluisfgarza',
    // },
    // {
    //   name: 'Twitter',
    //   url: 'https://twitter.com/jluisfgarza',
    // },
  ],
  navLinks: [
    {
      name: 'About',
      url: '/#about',
    },
    {
      name: 'Experience',
      url: '/#jobs',
    },
    {
      name: 'Work',
      url: '/#projects',
    },
    {
      name: 'Contact',
      url: '/#contact',
    },
    {
      name: 'Blog',
      url: '/blog',
    },
  ],
  colors: {
    blue: 'rgb(63, 81, 181)',
    navy: '#0a192f',
    darkNavy: '#020c1b',
  },
  navHeight: 100,
  srConfig: (delay = 200) => ({
    origin: 'bottom',
    distance: '20px',
    duration: 500,
    delay,
    rotate: { x: 0, y: 0, z: 0 },
    opacity: 0,
    scale: 1,
    easing: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
    mobile: true,
    reset: false,
    useDelay: 'always',
    viewFactor: 0.25,
    viewOffset: { top: 0, right: 0, bottom: 0, left: 0 },
  }),
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FormattedIcon } from "@components/icons";
import { socialMedia } from "@config";
import styled from "styled-components";
import { theme, mixins, media } from "@styles";
const { colors, fontSizes, fonts } = theme;

const Footer = (props) => {
  const [githubInfo, setGitHubInfo] = useState({
    stars: null,
    forks: null
  });

  useEffect(() => {
    if (process.env.NODE_ENV !== "production") {
      return;
    }
    fetch("https://api.github.com/repos/bchiang7/v4")
      .then((response) => response.json())
      .then((json) => {
        const { stargazers_count, forks_count } = json;
        setGitHubInfo({
          stars: stargazers_count,
          forks: forks_count
        });
      })
      .catch((e) => console.error(e));
  }, []);

  return (
    <StyledContainer>
      <StyledSocial>
        <StyledSocialList>
          {socialMedia &&
            socialMedia.map(({ name, url }, i) => (
              <li key={i}>
                <StyledSocialLink
                  href={url}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                  aria-label={name}
                >
                  <FormattedIcon name={name} />
                </StyledSocialLink>
              </li>
            ))}
        </StyledSocialList>
      </StyledSocial>
      {props.credits && (
        <StyledMetadata tabindex="-1">
          <StyledGitHubLink
            href="https://github.com/bchiang7/v4"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <div>Originaly Designed &amp; Built by Brittany Chiang</div>
            {githubInfo.stars && githubInfo.forks && (
              <StyledGitHubInfo>
                <span>
                  <FormattedIcon name="Star" />
                  <span>{githubInfo.stars.toLocaleString()}</span>
                </span>
                <span>
                  <FormattedIcon name="Fork" />
                  <span>{githubInfo.forks.toLocaleString()}</span>
                </span>
              </StyledGitHubInfo>
            )}
          </StyledGitHubLink>
        </StyledMetadata>
      )}
    </StyledContainer>
  );
};

Footer.propTypes = {
  githubInfo: PropTypes.object,
  credits: PropTypes.bool
};

export default Footer;

const StyledContainer = styled.footer`
  ${mixins.flexCenter};
  background-color: ${(props) => props.theme.navBackgroundAlt};
  flex-direction: column;
  padding: 10px;
  text-align: center;
  height: auto;
  min-height: 70px;
  box-shadow: 0 10px 30px -10px ${(props) => props.theme.navBackgroundAlt};
`;

const StyledSocial = styled.div`
  color: ${(props) => props.theme.navLogoAndText};
  width: 100%;
  max-width: 200px;
  margin: 5px auto 5px;
`;

const StyledSocialList = styled.ul`
  ${mixins.flexBetween};
  padding: 0;
  margin: 0;
  list-style: none;
`;

const StyledSocialLink = styled.a`
  padding: 10px;
  svg {
    width: 20px;
    height: 20px;
  }
`;

const StyledMetadata = styled.div`
  font-family: ${fonts.SFMono};
  font-size: ${fontSizes.xs};
  line-height: 1;
`;

const StyledGitHubLink = styled.a`
  color: ${colors.slate};
  padding: 10px;
`;

const StyledGitHubInfo = styled.div`
  margin-top: 10px;
  & > span {
    display: inline-flex;
    align-items: center;
    margin: 0 7px;
  }
  svg {
    display: inline-block;
    height: 15px;
    width: auto;
    margin-right: 5px;
  }
`;

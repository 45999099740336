import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { navLinks } from "@config";
import styled from "styled-components";
import { theme, mixins, media } from "@styles";
const { fontSizes, fonts } = theme;
import { Footer } from "@components";

const Menu = ({ menuOpen, toggleMenu }) => {
  const handleMenuClick = (e) => {
    const target = e.target;
    const isLink = target.hasAttribute("href");
    const isNotMenu =
      target.classList && target.classList[0].includes("StyledContainer");

    if (isLink || isNotMenu) {
      toggleMenu();
    }
  };

  return (
    <StyledContainer
      menuOpen={menuOpen}
      onClick={handleMenuClick}
      aria-hidden={!menuOpen}
      tabIndex={menuOpen ? 1 : -1}
    >
      <Sidebar>
        <NavLinks>
          <NavList>
            {navLinks &&
              navLinks.map(({ url, name }, i) => (
                <NavListItem key={i}>
                  <NavLink to={url}>{name}</NavLink>
                </NavListItem>
              ))}
          </NavList>
        </NavLinks>
        <ResumeLink
          href="/resume.pdf"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Resume
        </ResumeLink>
      </Sidebar>
      <StyledFooter>
        <Footer credits={false} />
      </StyledFooter>
    </StyledContainer>
  );
};

Menu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  toggleMenu: PropTypes.func.isRequired
};

export default Menu;

const StyledContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 10;
  outline: 0;
  transition: ${theme.transition};
  transform: translateY(calc(${(props) => (props.menuOpen ? 100 : 0)}% - 70px));
  overflow-y: scroll;
  visibility: ${(props) => (props.menuOpen ? "visible" : "hidden")};
`;

const Sidebar = styled.aside`
  ${mixins.flexCenter};
  flex-direction: column;
  background-color: ${(props) => props.theme.navBackground};
  padding: 50px;
  width: 100%;
  height: 100%;
  position: relative;
  right: 0;
  margin-left: auto;
  font-family: ${fonts.SFMono};
`;

const NavLinks = styled.nav`
  padding-top: 50px;
  padding-bottom: 20px;
  height: 100%;
  width: 200px;
  position: relative;
  right: 0;
  text-align: center;
  color: ${(props) => props.theme.navText};
`;

const NavList = styled.ol`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 100%;
`;

const NavListItem = styled.li`
  position: relative;
  font-size: ${fontSizes.lg};
  counter-increment: item 1;
  ${media.thone`font-size: ${fontSizes.md};`};
  ${media.tiny`font-size: ${fontSizes.smish};`};
  &:before {
    display: block;
    position: absolute;
    left: 0;
    content: "0" counter(item) ".";
    color: ${(props) => props.theme.navTitle};
    font-size: ${fontSizes.sm};
    margin-top: 5px;
  }
`;

const NavLink = styled(Link)`
  ${mixins.link};
  padding: 3px 20px 20px;
  width: 100%;
`;

const ResumeLink = styled.a`
  ${mixins.bigButton};
  padding: 15px 30px;
  margin: auto;
`;

const StyledFooter = styled.div`
  z-index: 9999px;
  height: 100%;
`;

export default {
  navLogoAndText: 'rgb(63, 81, 181)',
  navBackground: '#e6f1ff',
  navBackgroundAlt: '#dce7ff',
  navText: '#141416',
  navTitle: 'rgb(70, 81, 200)',

  loaderText: '#e6f1ff',
  loaderBackground: 'rgb(63, 81, 181)',

  textColor: '#141416',
  textColorReverse: '#e6f1ff',
  textWhite: '#e6f1ff',
  titleColor: 'rgb(63, 81, 181)',
  titleColorAlt: '#141416',

  featuredLabel: '#141416',
  featuredName: 'rgb(63, 81, 181)',

  white: '#e6f1ff',
  lightGrey: '#606a86',
  blue: 'rgb(63, 81, 181)',
  navy: '#141416',
  transBlue: 'rgba(63, 81, 181, 0.2)',
};

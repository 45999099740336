import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import anime from "animejs";
import { IconLoader } from "@components/icons";
import styled, { keyframes } from "styled-components";
import { theme, mixins } from "@styles";
import { rotateIn } from "react-animations";

const loaderAnimation = keyframes`${rotateIn}`;

const Loader = ({ finishLoading }) => {
  const animate = () => {
    const loader = anime.timeline({
      complete: () => finishLoading()
    });
    loader
      .add({
        targets: "#logo #juanluisfg",
        duration: 700,
        easing: "easeInOutQuart",
        opacity: 1
      })
      .add({
        targets: "#logo",
        delay: 500,
        duration: 300,
        easing: "easeInOutQuart",
        opacity: 0,
        scale: 0.1
      })
      .add({
        targets: ".loader",
        duration: 200,
        easing: "easeInOutQuart",
        opacity: 0,
        zIndex: -1
      });
  };

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 10);
    animate();
    return () => clearTimeout(timeout);
  }, []);

  return (
    <StyledContainer className="loader">
      <Helmet bodyAttributes={{ class: `hidden` }} />
      <StyledLogo isMounted={isMounted}>
        <IconLoader />
      </StyledLogo>
    </StyledContainer>
  );
};

Loader.propTypes = {
  finishLoading: PropTypes.func.isRequired
};

export default Loader;

const StyledContainer = styled.div`
  ${mixins.flexCenter};
  background-color: ${(props) => props.theme.loaderBackground};
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
`;

const StyledLogo = styled.div`
  width: max-content;
  max-width: 200px;
  transition: ${theme.transition};
  opacity: ${(props) => (props.isMounted ? 1 : 0)};
  color: ${(props) => props.theme.loaderText};
  animation: 2s ${loaderAnimation};
  svg {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    fill: none;
    user-select: none;
    #juanluisfg {
      opacity: 0;
    }
  }
`;

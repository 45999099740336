import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { email } from "@config";
import styled from "styled-components";
import { theme, mixins, media, Section } from "@styles";
const { fontSizes, fonts, navDelay, loaderDelay } = theme;

const Hero = ({ data }) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    return () => clearTimeout(timeout);
  }, []);
  const { frontmatter, html } = data[0].node;
  const one = () => (
    <StyledOverline style={{ transitionDelay: "100ms" }}>
      {frontmatter.title}
    </StyledOverline>
  );
  const two = () => (
    <StyledTitle style={{ transitionDelay: "200ms" }}>
      {frontmatter.name}.
    </StyledTitle>
  );
  const three = () => (
    <StyledSubtitle style={{ transitionDelay: "300ms" }}>
      {frontmatter.subtitle}
    </StyledSubtitle>
  );
  const four = () => (
    <StyledDescription
      style={{ transitionDelay: "400ms" }}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  );
  const five = () => (
    <div style={{ transitionDelay: "500ms" }}>
      <StyledEmailLink href={`mailto:${email}`}>Get In Touch</StyledEmailLink>
      <StyledResumeButton
        href="/resume.pdf"
        target="_blank"
        rel="nofollow noopener noreferrer"
      >
        Resume
      </StyledResumeButton>
    </div>
  );
  const items = [one, two, three, four, five];

  return (
    <StyledContainer>
      <TransitionGroup component={null}>
        {isMounted &&
          items.map((item, i) => (
            <CSSTransition key={i} classNames="fadeup" timeout={loaderDelay}>
              {item}
            </CSSTransition>
          ))}
      </TransitionGroup>
    </StyledContainer>
  );
};

Hero.propTypes = {
  data: PropTypes.array.isRequired
};

export default Hero;

const StyledContainer = styled(Section)`
  ${mixins.flexCenter};
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;
  ${media.tablet`padding-top: 150px;`};
  div {
    width: 100%;
  }
`;

const StyledOverline = styled.h1`
  color: ${(props) => props.theme.titleColorAlt};
  margin: 0 0 20px 3px;
  font-size: ${fontSizes.md};
  font-family: ${fonts.SFMono};
  font-weight: normal;
  ${media.desktop`font-size: ${fontSizes.sm};`};
  ${media.tablet`font-size: ${fontSizes.smish};`};
`;

const StyledTitle = styled.h2`
  color: ${(props) => props.theme.titleColor};
  font-size: 80px;
  line-height: 1.1;
  margin: 0;
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;

const StyledSubtitle = styled.h3`
  font-size: 80px;
  line-height: 1.1;
  color: ${(props) => props.theme.titleColorAlt};
  ${media.desktop`font-size: 70px;`};
  ${media.tablet`font-size: 60px;`};
  ${media.phablet`font-size: 50px;`};
  ${media.phone`font-size: 40px;`};
`;

const StyledDescription = styled.div`
  margin-top: 25px;
  width: 50%;
  max-width: 500px;
  color: ${(props) => props.theme.textColor};
  a {
    ${mixins.inlineLink};
  }
`;

const StyledEmailLink = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
`;

const StyledResumeButton = styled.a`
  ${mixins.bigButton};
  margin-top: 50px;
  margin-left: 10px;
`;

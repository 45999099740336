import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { throttle } from "@utils";
import { navHeight } from "@config";
import { Menu } from "@components";
import { IconLogo } from "@components/icons";
import styled from "styled-components";
import { theme, mixins, media } from "@styles";
import Toggle from "./Theme/Toggle";
const { fonts, loaderDelay, navDelay } = theme;

const DELTA = 3;

const Nav = ({ isHome }) => {
  const [isMounted, setIsMounted] = useState(!isHome);
  const [menuOpen, setmenuOpen] = useState(false);
  const [scrollDirection, setscrollDirection] = useState("none");
  const [lastScrollTop, setlastScrollTop] = useState(0);

  const toggleMenu = () => setmenuOpen(!menuOpen);

  const handleScroll = () => {
    const fromTop = window.scrollY;
    // Make sure they scroll more than DELTA
    if (!isMounted || Math.abs(lastScrollTop - fromTop) <= DELTA || menuOpen) {
      return;
    }
    if (fromTop < DELTA) {
      setscrollDirection("none");
    } else if (fromTop > lastScrollTop && fromTop > navHeight) {
      if (scrollDirection !== "down") {
        setscrollDirection("down");
      }
    } else if (fromTop + window.innerHeight < document.body.scrollHeight) {
      if (scrollDirection !== "up") {
        setscrollDirection("up");
      }
    }
    setlastScrollTop(fromTop);
  };

  const handleResize = () => {
    if (window.innerWidth > 768 && menuOpen) {
      toggleMenu();
    }
  };

  const handleKeydown = (e) => {
    if (!menuOpen) {
      return;
    }
    if (e.which === 27 || e.key === "Escape") {
      toggleMenu();
    }
  };

  const timeout = isHome ? loaderDelay : 0;
  const fadeClass = isHome ? "fade" : "";

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), navDelay);
    window.addEventListener("scroll", () => throttle(handleScroll()));
    window.addEventListener("resize", () => throttle(handleResize()));
    window.addEventListener("keydown", (e) => handleKeydown(e));
    return () => {
      clearTimeout(timeout);
      window.removeEventListener("scroll", () => handleScroll());
      window.removeEventListener("resize", () => handleResize());
      window.removeEventListener("keydown", (e) => handleKeydown(e));
    };
  }, [handleKeydown, handleResize, handleScroll]);

  return (
    <StyledContainer scrollDirection={scrollDirection}>
      <Helmet>
        <body className={menuOpen ? "blur" : ""} />
      </Helmet>
      <StyledNav>
        <TransitionGroup component={null}>
          {isMounted && (
            <CSSTransition classNames={fadeClass} timeout={timeout}>
              <StyledLogo tabindex="-1">
                {isHome ? (
                  <a href="/" aria-label="home">
                    <IconLogo />
                  </a>
                ) : (
                  <Link to="/" aria-label="home">
                    <IconLogo />
                  </Link>
                )}
              </StyledLogo>
            </CSSTransition>
          )}
        </TransitionGroup>
        <TransitionGroup component={null}>
          {isMounted && (
            <StyledHamburger>
              <CSSTransition classNames={fadeClass} timeout={timeout}>
                <Toggle />
              </CSSTransition>
              <CSSTransition classNames={fadeClass} timeout={timeout}>
                <StyledHamburgerBox onClick={toggleMenu}>
                  <StyledHamburgerInner menuOpen={menuOpen} />
                </StyledHamburgerBox>
              </CSSTransition>
            </StyledHamburger>
          )}
        </TransitionGroup>
      </StyledNav>
      <Menu menuOpen={menuOpen} toggleMenu={toggleMenu} />
    </StyledContainer>
  );
};

Nav.propTypes = {
  isHome: PropTypes.bool
};

export default Nav;

const StyledContainer = styled.header`
  ${mixins.flexBetween};
  position: fixed;
  top: 0;
  padding: 0px 50px;
  background-color: ${(props) => props.theme.navBackground};
  transition: ${theme.transition};
  z-index: 11;
  filter: none !important;
  pointer-events: auto !important;
  user-select: auto !important;
  width: 100%;
  height: ${(props) =>
    props.scrollDirection === "none" ? theme.navHeight : theme.navScrollHeight};
  box-shadow: ${(props) =>
    props.scrollDirection === "up"
      ? `0 2px 5px -2px  ${props.theme.navBackgroundAlt}`
      : "none"};
  transform: translateY(
    ${(props) =>
      props.scrollDirection === "down" ? `-${theme.navScrollHeight}` : "0px"}
  );
  ${media.desktop`padding: 0 40px;`};
  ${media.tablet`padding: 0 25px;`};
`;

const StyledNav = styled.nav`
  ${mixins.flexBetween};
  position: relative;
  width: 100%;
  color: ${(props) => props.theme.textColor};
  font-family: ${fonts.SFMono};
  counter-reset: item 0;
  z-index: 12;
`;

const StyledLogo = styled.div`
  ${mixins.flexCenter};
  a {
    display: block;
    color: ${(props) => props.theme.navLogoAndText};
    width: 42px;
    height: 42px;
    &:hover,
    &:focus {
      svg {
        fill: ${(props) => props.theme.transBlue};
      }
    }
    svg {
      fill: none;
      transition: ${theme.transition};
      user-select: none;
    }
  }
`;

const StyledHamburger = styled.div`
  ${mixins.flexCenter};
  overflow: visible;
  margin: 0 -12px 0 0;
  padding: 15px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: 0.15s;
  transition-property: opacity, filter;
  text-transform: none;
  border: 0;
  display: flex;
  color: ${(props) => props.theme.navLogoAndText};
`;

const StyledHamburgerBox = styled.div`
  position: relative;
  display: inline-block;
  width: ${theme.hamburgerWidth}px;
  height: 24px;
  margin-left: 25px;
`;

const StyledHamburgerInner = styled.div`
  background-color: ${(props) => props.theme.navLogoAndText};
  position: absolute;
  width: ${theme.hamburgerWidth}px;
  height: 2px;
  border-radius: ${theme.borderRadius};
  top: 50%;
  left: 0;
  right: 0;
  transition-duration: 0.22s;
  transition-property: transform;
  transition-delay: ${(props) => (props.menuOpen ? `0.12s` : `0s`)};
  transform: rotate(${(props) => (props.menuOpen ? `225deg` : `0deg`)});
  transition-timing-function: cubic-bezier(
    ${(props) =>
      props.menuOpen ? `0.215, 0.61, 0.355, 1` : `0.55, 0.055, 0.675, 0.19`}
  );
  &:before,
  &:after {
    content: "";
    display: block;
    background-color: ${(props) => props.theme.navLogoAndText};
    position: absolute;
    left: auto;
    right: 0;
    width: ${theme.hamburgerWidth}px;
    height: 2px;
    transition-timing-function: ease;
    transition-duration: 0.15s;
    transition-property: transform;
    border-radius: 4px;
  }
  &:before {
    width: ${(props) => (props.menuOpen ? `100%` : `120%`)};
    top: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    opacity: ${(props) => (props.menuOpen ? 0 : 1)};
    transition: ${(props) =>
      props.menuOpen ? theme.hamBeforeActive : theme.hamBefore};
  }
  &:after {
    width: ${(props) => (props.menuOpen ? `100%` : `80%`)};
    bottom: ${(props) => (props.menuOpen ? `0` : `-10px`)};
    transform: rotate(${(props) => (props.menuOpen ? `-90deg` : `0`)});
    transition: ${(props) =>
      props.menuOpen ? theme.hamAfterActive : theme.hamAfter};
  }
`;

import React, { useContext } from "react";
import styled from "styled-components";
import { CgSun } from "react-icons/cg";
import { HiMoon } from "react-icons/hi";
import ThemeContext from "./ThemeProvider";

const Toggle = () => {
  const { theme, toggle } = useContext(ThemeContext);
  const icon = theme == "light" ? <HiMoon size={30} /> : <CgSun size={30} />;

  return <ToggleBtn onClick={() => toggle()}> {icon} </ToggleBtn>;
};

export default Toggle;

const ToggleBtn = styled.button`
  cursor: pointer;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  margin-left: auto;
  margin-right: auto;
  background-color: ${(props) => props.theme.navBackground};
  color: ${(props) => props.theme.navLogoAndText};

  &:focus {
    outline: none;
  }
  transition: all 0.5s ease;
`;
